import React, { useEffect, useState } from "react";
import NoData from "../../../components/noData";
import Loading from "../../../components/loading";
import { BsFillBookmarkFill } from "react-icons/bs";
import API from "../../../config/API";
import { useSelector } from "react-redux";
import { DELETE, GET } from "../../../utils/apiCalls";
import { Avatar, List, Pagination, Popconfirm, notification } from "antd";
import { useNavigate } from "react-router-dom";

const ProfileFavourites = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [orders, setOrders] = useState<any[]>([]);
  const [prices, setPrices] = useState<Record<string, number>>({});
  const [notificationApi, contextHolder] = notification.useNotification();
  const [meta, setMeta] = useState<any>({});
  const [page, setPage] = useState(1);
  const pageSize = 10;
  
  const Settings = useSelector((state: any) => state.Settings.Settings);
  const liverate = useSelector((state: any) => state.Liverate.liverate);
  const Auth = useSelector((state: any) => state.User);
  
  const navigate = useNavigate();

  const getVariantInfo = (data: any) => {
    let variantss = "";
    if (Array.isArray(data?.variantDetails?.combination)) {
      data?.variantDetails?.combination.forEach((item: any) => {
        variantss += `${item.value} `;
      });
    }
    return variantss.trim();
  };

  const fetchProductDetails = async (productId: string) => {
    const url = Auth?.auth ? API.PRODUCTS_GETONE_LOGIN : API.PRODUCTS_GETONE;
    try {
      const response: any = await GET(url + productId, null);
      if (response.status) {
        return response.data;
      }
    } catch (err) {
      console.error('Error fetching product details:', err);
      return null;
    }
  };

  const calculatePrice = async (item: any) => {
    try {
      const productDetails = await fetchProductDetails(item.pid);
      if (!productDetails) return 0;

      const { price_method, metal_type, weight, vat, labour_charge } = productDetails;
      
      if (price_method === "Live") {
        const rate = liverate.find((i: any) => i.metal_type === metal_type);
        if (!rate) return 0;

        const basePrice = Number(rate.ask) * Number(weight);
        const labourCharge = (basePrice / 100) * Number(labour_charge);
        const subtotal = basePrice + labourCharge;
        const vatAmount = (subtotal * Number(vat)) / 100;
        return Number((subtotal + vatAmount).toFixed(2));
      }
      
      return Number(productDetails.purchase_rate);
    } catch (err) {
      console.error('Error calculating price:', err);
      return 0;
    }
  };

  const calculateAllPrices = async (items: any[]) => {
    const newPrices: Record<string, number> = {};
    for (const item of items) {
      newPrices[item.pid] = await calculatePrice(item);
    }
    setPrices(newPrices);
  };

  const getFavourites = async (currPage: number = page) => {
    const url = API.WISHLIST_GETALL + `?order=ASC&page=${currPage}&take=${pageSize}`;
    setIsLoading(true);
    try {
      const favorites: any = await GET(url, null);
      if (favorites.status) {
        setOrders(favorites?.data);
        setMeta(favorites?.meta);
        await calculateAllPrices(favorites?.data);
      } else {
        notificationApi.error({ message: favorites.message ?? "Failed to fetch favorites" });
      }
    } catch (err) {
      notificationApi.error({ message: "Failed to get Favorites." });
    } finally {
      setIsLoading(false);
    }
  };

  const deleteItem = async (item: any) => {
    const url = API.WISHLIST + item?.id;
    try {
      const deleted: any = await DELETE(url);
      if (deleted.status) {
        await getFavourites(page);
        notificationApi.success({ message: "Item removed from wishlist." });
      }
    } catch (err) {
      notificationApi.error({ message: "Something went wrong!" });
    }
  };

  const changePage = async (newPage: number) => {
    window.scrollTo(0, 0);
    setPage(newPage);
    await getFavourites(newPage);
  };

  useEffect(() => {
    getFavourites();
  }, []);

  return (
    <>
      <div className="mb-3">
        {contextHolder}
        {isLoading ? (
          <Loading />
        ) : orders?.length ? (
          <List
            header={<h5>{`My Wishlist (${meta.itemCount ?? 0})`}</h5>}
            itemLayout="horizontal"
            dataSource={orders}
            renderItem={(item: any) => (
              <List.Item
                actions={[
                  <Popconfirm
                    placement="bottomRight"
                    title="Are you sure to Remove item from wishlist?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => deleteItem(item)}
                  >
                    <a key="list-loadmore-edit" className="text-danger">
                      Delete
                    </a>
                  </Popconfirm>,
                ]}
              >
                <List.Item.Meta
                  avatar={
                    <Avatar
                      src={item?.image ?? ""}
                      shape="square"
                      size={80}
                      onClick={() => {
                        navigate(
                          `/product/details?pid=${item?.pid}&name=${item?.productDetails?.name}`
                        );
                      }}
                    />
                  }
                  title={
                    <span style={{ textTransform: "capitalize" }}>
                      {item?.productDetails?.name ?? ""} {getVariantInfo(item)}
                    </span>
                  }
                  description={
                    <div>
                      <p className="mb-0">
                        {item?.productDetails?.description?.length > 50
                          ? item?.productDetails?.description.slice(0, 50) + "..."
                          : item?.productDetails?.description}
                      </p>

                      {item?.productDetails?.status === false ||
                      item.productDetails?.unit === 0 ? (
                        <h6 className="mt-0 text-danger">Out of Stock</h6>
                      ) : (
                        <h6 className="mt-0 text-dark">
                          {prices[item.pid]} {Settings.currency ?? ""}
                        </h6>
                      )}
                    </div>
                  }
                />
              </List.Item>
            )}
          />
        ) : (
          <NoData
            icon={<BsFillBookmarkFill size={70} color="#e6e6e6" />}
            header="No Favorites"
            text1="You have no items in your favourites"
          />
        )}
        <div className="d-flex justify-content-center mt-3">
          <Pagination
            current={page}
            pageSize={pageSize}
            total={meta?.itemCount || 0}
            defaultCurrent={1}
            responsive={true}
            defaultPageSize={pageSize}
            disabled={false}
            hideOnSinglePage={true}
            onChange={changePage}
          />
        </div>
      </div>
    </>
  );
};

export default ProfileFavourites;