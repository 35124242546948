import React from "react";
import "./styles.scss";
import Logo from "../../assets/images/logowhite.png";
import Logo2 from "../../config/logo2.png";
import { Col, Container, Row } from "react-bootstrap";
import {
  IoLogoWhatsapp,
  IoLogoInstagram,
  IoLocationOutline,
} from "react-icons/io5";
import {
  RiFacebookFill,
  RiTiktokFill,
  RiSnapchatLine,
  RiLinkedinFill,
  RiTwitterXFill,
} from "react-icons/ri";
import API from "../../config/API";
import { useTranslation } from "react-i18next";
import { FiArrowUpRight } from "react-icons/fi";

function ShareScreen() {
  const { t } = useTranslation();
  const handleLinkClick = (link: string) => {
    window.open(link, "_blank");
  };

  const contactNumber = API.CONTACT_NUMBER.replace(/\s+/g, "");
  let item: any = [
    {
      icon: <IoLogoWhatsapp />,
      text: "WhatsApp",
      link: `${API.CONTACT_NUMBER}`,
      fullLink: `https://wa.me/${contactNumber}`,
    },
    {
      icon: <img src={Logo2} style={{width:20}} />,
      text: "Website",
      link: `${API.WEBSITE}`,
      fullLink: `https://${API.WEBSITE}`,
    },
    {
      icon: <RiFacebookFill />,
      text: "Facebook",
      link: "luckystargoldllc",
      fullLink: "https://www.facebook.com/luckystargoldllc",
    },
    {
      icon: <IoLogoInstagram />,
      text: "Instagram",
      link: "@luckystargoldgcc",
      fullLink:
        "https://www.instagram.com/luckystargoldgcc/?igsh=bzBvd296NHA0NGxy",
    },
    {
      icon: <RiTwitterXFill />,
      text: "X",
      link: "twitter.com/Luckystargold",
      fullLink: "https://twitter.com/Luckystargold",
    },
    {
      icon: <RiSnapchatLine />,
      text: "SnapChat",
      link: "snapchat.com/V6PsM13H",
      fullLink: "https://t.snapchat.com/V6PsM13H",
    },
    {
      icon: <RiTiktokFill />,
      text: "TikTok",
      link: "tiktok.com/@lsg_dxb",
      fullLink: "https://www.tiktok.com/@lucky.star.gold?_t=8orc9lLEpbG&_r=1",
    },
    {
      icon: <RiLinkedinFill />,
      text: "LinkedIn",
      link: "lsg-traiding-4a60602b4/",
      fullLink: "https://www.linkedin.com/in/lsg-traiding-4a60602b4/",
    },
    {
      icon: <IoLocationOutline />,
      text: "Location",
      link: "luckystargold.com",
      fullLink:
        "https://www.google.com/maps/place/Lucky+star+gold/@25.271824,55.2960019,17z/data=!3m1!4b1!4m6!3m5!1s0x3e5f4310e2017d99:0x8d38c0bb76c60af3!8m2!3d25.271824!4d55.2985768!16s%2Fg%2F11kpp_jcyr?entry=ttu",
    },
  ];

  return (
    <div className="ShareScreen">
      <Container>
        <Row>
          <Col sm={12} md={12} xs={12}>
            <br />
            <div className="ShareScreen-titile">
              <div className="ShareScreen-logo">
                <img src={Logo} />
              </div>
              <div className="ShareScreen-logoBorder" />
              <div className="ShareScreen-txt2">
                {t("We_believe_that_the_best")}
                <br className="d-none d-sm-block" />
                {t("We_believe_that_the_best1")}
              </div>
            </div>

            <div style={{ margin: 20 }} />
          </Col>
          <Col md={2} xs={12} />
          <Col sm={8} md={8} xs={12}>
            <Row>
              {item?.map((item: any) => {
                return (
                  <Col sm={6} xs={12}>
                    <div
                      className="ShareScreen-box2"
                      onClick={() => handleLinkClick(item?.fullLink)}
                    >
                      <div className="ShareScreen-box3">{item?.icon}</div>
                      <div style={{ flexGrow: 1 }}>
                        <div className="ShareScreen-txt3">{item?.text}</div>
                        <div className="ShareScreen-txt4">{item?.link}</div>
                      </div>
                      <div>
                        <FiArrowUpRight color={"#fff"} size={20} />
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Col>
          <Col md={2} xs={12}></Col>
        </Row>
      </Container>
      <br />
    </div>
  );
}

export default ShareScreen;
