import React from "react";
import { Form, Select } from "antd";
import Country from "../../config/countryCode.json";
import _ from 'lodash';
const PrefixSelector = () => {
  const uniqueCountries = _.uniqBy(Country, 'dial_code');

  return (
    // <Form.Item
    //   name="code"
    //   noStyle
    //   rules={[{ required: true, message: "Please select countrycode" }]}
    // >
    //   <Select style={{ width: 85 }} size="large" showSearch={true}>
    //     {Country.map((item: any) => (
    //       <Select.Option key={item.dial_code} value={item.dial_code}>
    //         {item.dial_code}
    //       </Select.Option>
    //     ))}
    //   </Select>
    // </Form.Item>
  <Form.Item
    name="code"
    noStyle
    rules={[{ required: true, message: "Please select countrycode" }]}
  >
    <Select 
      style={{ width: 85 }} 
      size="large" 
      showSearch
      optionFilterProp="children"
    >
      {uniqueCountries.map((item) => (
        <Select.Option 
          key={item.dial_code} 
          value={item.dial_code}
        >
          {item.dial_code}
        </Select.Option>
      ))}
    </Select>
  </Form.Item>
  );
};

export default PrefixSelector;
