import { Card, Tag } from "antd";
import Meta from "antd/es/card/Meta";
import React from "react";

function AddressCardAuth(props: any) {
  return (
    <Card bordered={false}>
      <Meta
        title="Delivery Address"
        description={
          <div className="text-dark">
            <div>
              {" "}
              <Tag bordered={false} className="fw-bold" color="blue">
                {props?.data?.addressDetails?.type}
              </Tag>
            </div>
            <div className="fw-bold">{props?.data?.userDetails?.name}</div>

            <div>City: {props?.data?.addressDetails?.city}</div>
            <div>PinCode: {props?.data?.addressDetails?.pin_code}</div>
            <div>State: {props?.data?.addressDetails?.state}</div>

            <div>
              Address: {props?.data?.addressDetails?.fullAddress},
              {props?.data?.addressDetails?.geo_location}
            </div>
            {props?.data?.addressDetails?.lat &&
            props?.data?.addressDetails?.long ? (
              <div>
                Location:
                <a
                  href={`https://www.google.com/maps?q=${props?.data?.addressDetails?.lat},${props?.data?.addressDetails?.long}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  LAT: {props?.data?.addressDetails?.lat} LONG:{" "}
                  {props?.data?.addressDetails?.long}
                </a>
              </div>
            ) : null}

            <div className="fw-bold">
              Phone Number: {props?.data?.addressDetails?.code ?? ""}{" "}
              {props?.data?.addressDetails?.alt_phone}
            </div>
          </div>
        }
      />
    </Card>
  );
}

export default AddressCardAuth;
