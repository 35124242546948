import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const PageSider = (props: any) => {
  const navigate = useNavigate();

  const Category = useSelector((state: any) => state.Category.categries);
  const CategoryLink = (categoryId: string, categoryName: string) => {
    const encodedCategoryId = window.btoa(categoryId);
    const path = `/products/category?id=${encodedCategoryId}&type=${categoryName}`;
    navigate(path);
  };

  return (
    <div style={{ paddingTop: 20 ,height:"112vh"}}>
      {Category?.map((item: any, idx: number) => {
        return (
          <div>
            {item?.sub_categories?.map((subCat: any, i: number) => {
              return (
                <div
                  key={i}
                  onClick={() => CategoryLink(subCat._id, subCat.name)}
                  className={
                    props?.categoryId === subCat._id
                      ? "productByCat-PageSiderItem active"
                      : "productByCat-PageSiderItem"
                  }
                >
                  <div>
                    <img src={subCat?.image} className="productByCat-img" />
                  </div>
                  <div>{subCat?.name}</div>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
export default PageSider;
