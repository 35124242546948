import moment from "moment";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

function GoldPrice() {
  const Liverate = useSelector((state: any) => state.Liverate);
  return (
    <Container fluid className="Offers-box">
      <div className="HomeScreen-GoldPrice">
        <div className="HomeScreen_text1">TODAY'S RETAIL GOLD JEWELLERY PRICE</div>
        <div className="HomeScreen_text2">{moment(new Date()).format("DD-MM-YYYY")} - RATE IN AED PER GM</div>
        <br/>
        <Row>
          {Liverate?.liverate?.map((item: any) => (
            <Col  key={item.name} style={{marginBottom:20}}>
              <div className="HomeScreen-GoldPriceBox">
                {item.name} - <b style={{color:"#b8821f",fontSize:20}}>{item.ask} </b>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </Container>
  );
}

export default GoldPrice;
