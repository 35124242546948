import { useEffect, useState } from "react";
import { Button, Form, Input, Select } from "antd";
import { Row, Col, Container } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import TextArea from "antd/es/input/TextArea";
import Loading from "../../../components/loading";
import React from "react";
import { useSelector } from "react-redux";
import API from "../../../config/API";
import { GET } from "../../../utils/apiCalls";
function EditProductInformations(props: any) {
  const [subCategories, setSubCategories] = useState<any>([]);
  const [brands, setBrands] = useState<any[]>([]);
  const [purity, setPurity] = useState<any[]>([]);
  console.log("purity====purity=====", purity)
  const [specifications, setSpecifications] = useState(
    props?.data?.description
  );
  const categories = useSelector((state: any) => state.Category?.categries);
  const [form] = Form.useForm();
  const categoryChangeHandler = (value: any) => {
    if (Array.isArray(categories)) {
      const index: any = categories?.findIndex((item: any) => item.id == value);
      if (index != -1) {
        let item = categories[index]?.sub_categories;
        setSubCategories(item);
      }
    }
  };
  const submit = async (values: any) => {
    try {
      let obj = values;
      obj["specifications"] = specifications;
      props.onChange(obj);
    } catch (err) {
      console.log("err", err);
    }
  };
  const getBrands = async () => {
    const url = API.GET_BRAND;
    try {
      const response: any = await GET(url, null);
      if (response?.status) {
        if (Array.isArray(response?.data)) {
          const datas = response?.data?.map((item: any) => ({
            value: item?.name,
            label: item?.name,
          }));
          setBrands(datas);
        }
      }
    } catch (err) { }
  };

  console.log('purity -->',purity)

  
  const getPurities = async () => {
    const url = API.GET_PURITY_ALL;
    try {
      const response: any = await GET(url, null);
      if (response?.status) {
        if (Array.isArray(response?.data)) {
          const datas = response?.data?.map((item: any, idx: number) => ({
            value: idx.toString(),
            label: item?.metalType,
          }));
          setPurity(datas);
        }
      }
    } catch (err) { }
  };
  //get categories=============================================================
  useEffect(() => {
    form.setFieldsValue({
      bar_code: props?.data?.bar_code,
      sku: props?.data?.sku,
      brand: props?.data?.brand,
      bulk_order: props?.data?.bulk_order == true ? "accept" : "notaccept",
      category: String(props?.data?.category),
      manufacture: props?.data?.manufacture,
      purchase_rate: props?.data?.purchase_rate,
      retail_rate: props?.data?.retail_rate,
      status: props?.data?.status == true ? "available" : "notavailable",
      subCategory: String(props?.data?.subCategory),
      name: props?.data?.name,
      description: props?.data?.description,
      unit: props?.data?.unit,
      units: props?.data?.units,
      metal_type: props?.data?.metal_type,
      weight: props?.data?.weight,
      net_weight: props?.data?.net_weight,
      stone_weight: props?.data?.stone_weight,
      purity: props?.data?.purity,
      labour_charge: props?.data?.labour_charge,
      discount: props?.data?.discount,
      premium: props?.data?.premium,
      price_method: props?.data?.price_method,
      gender: props?.data?.gender,
      vat: props?.data?.vat,
    });
    setSpecifications(props?.data?.specifications);
  }, [props?.data]);
  useEffect(() => {
    categoryChangeHandler(props?.data?.category);
  }, [props?.data?.category]);
  useEffect(() => {
    getBrands();
    getPurities();
  }, []);
  return (
    <Container>
      {props?.productLoading ? (
        <Loading />
      ) : (
        <Form onFinish={submit} form={form}>
          <Row>
            <Col sm={6}>
              <div className="input-form-label">Category *</div>
              <Form.Item
                name={"category"}
                rules={[{ required: true, message: "Please chose Category" }]}
              >
                <Select
                  style={{ width: "100%" }}
                  className="border rounded"
                  allowClear
                  onChange={categoryChangeHandler}
                  placeholder="Select category"
                >
                  {props?.categories?.map((item: any, index: number) => (
                    <Select.Option key={index} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <div className="input-form-label">Sub Category *</div>
              <Form.Item
                name={"subCategory"}
                rules={[
                  { required: true, message: "Please chose Subcategory" },
                ]}
              >
                <Select
                  style={{ width: "100%" }}
                  className="border rounded"
                  allowClear
                  onChange={categoryChangeHandler}
                  placeholder="Select category"
                >
                  {subCategories?.map((item: any, index: number) => (
                    <Select.Option key={index} value={item._id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <div className="input-form-label">Product Title *</div>
              <Form.Item
                name={"name"}
                rules={[
                  { required: true, message: "Please Enter Product Name" },
                  { max: 200, message: "Product name length is too much" },
                ]}
              >
                <Input placeholder="Title" />
              </Form.Item>
              <div className="input-form-label">Description *</div>
              <Form.Item
                name={"description"}
                rules={[
                  {
                    required: true,
                    message: "Please Enter product Description",
                  },
                  {
                    max: 250,
                    message: "Product Description length is too much",
                  },
                ]}
              >
                <TextArea rows={4} placeholder="Description" maxLength={250} />
              </Form.Item>
              <Row>
                <Col sm={6}>
                  <div className="input-form-label">Metal Type*</div>
                  <Form.Item
                    name={"metal_type"}
                    rules={[
                      { required: true, message: "Please Select Metal Type" },
                    ]}
                  >
                    <Select>
                      <Select.Option value={"gold24"}>
                        Gold 24 Karat
                      </Select.Option>
                      <Select.Option value={"gold22"}>
                        Gold 22 Karat
                      </Select.Option>
                      <Select.Option value={"gold21"}>
                        Gold 21 Karat
                      </Select.Option>
                      <Select.Option value={"gold18"}>
                        Gold 18 Karat
                      </Select.Option>
                      <Select.Option value={"silver"}>Silver</Select.Option>
                    </Select>
                  </Form.Item>
                  <div className="input-form-label">Net Weight* (Gram)</div>
                  <Form.Item
                    name={"net_weight"}
                    rules={[{ required: true, message: "Please Enter Weight" }]}
                  >
                    <Input type="number" placeholder="Net Weight" />
                  </Form.Item>
                  <div className="input-form-label">Purity*</div>
                  <Form.Item
                    name={"purity"}
                    rules={[{ required: true, message: "Please Enter purity" }]}
                  >
                    <Select
                      style={{ width: "100%" }}
                      className="border rounded"
                      allowClear
                      onChange={(val)=> console.log("====>>>",val)}
                      placeholder="Select Purity"
                    >
                      {purity?.map((item: any, index: number) => (
                        <Select.Option key={index} value={item.value}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <div className="input-form-label">Discount*</div>
                  <Form.Item
                    name={"discount"}
                    rules={[
                      { required: true, message: "Please Enter Discount" },
                    ]}
                  >
                    <Input type="number" placeholder="Discount" />
                  </Form.Item>
                </Col>
                <Col sm={6}>
                  <div className="input-form-label">Gross Weight* (gram)</div>
                  <Form.Item
                    name={"weight"}
                    rules={[{ required: true, message: "Please Enter Weight" }]}
                  >
                    <Input type="number" placeholder="Gross Weight" />
                  </Form.Item>
                  <div className="input-form-label">Stone Weight* (Gram)</div>
                  <Form.Item
                    name={"stone_weight"}
                    rules={[{ required: true, message: "Please Enter Weight" }]}
                  >
                    <Input type="number" placeholder="Stone Weight" />
                  </Form.Item>
                  <div className="input-form-label">Making Charge (%)</div>
                  <Form.Item
                    name={"labour_charge"}
                    rules={[{ required: true, message: "Please Enter charge" }]}
                  >
                    <Input type="number" placeholder="Making Charge" />
                  </Form.Item>
                  <div className="input-form-label">Premium</div>
                  <Form.Item
                    name={"premium"}
                    rules={[
                      { required: true, message: "Please Enter premium" },
                    ]}
                  >
                    <Input type="number" placeholder="Premium" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col sm={6}>
              <div className="input-form-label">Brand</div>
              <Form.Item
                name={"brand"}
                rules={[
                  { required: true, message: "Please Enter Brand Name" },
                  { max: 200, message: "Length is too much" },
                ]}
              >
                <Select
                  style={{ width: "100%" }}
                  className="border rounded"
                  allowClear
                  placeholder="Select Brand"
                >
                  {brands?.map((item: any, index: number) => (
                    <Select.Option key={index} value={item.value}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <div className="input-form-label">Manufactor</div>
              <Form.Item
                name={"manufacture"}
                rules={[
                  { required: true, message: "Please Enter Manufacturer" },
                  { max: 200, message: "Length is too much" },
                ]}
              >
                <Input placeholder="Manufactor" />
              </Form.Item>
              <Row>
                <Col sm={6}>
                  <div className="input-form-label">Product SKU*</div>
                  <Form.Item
                    name={"sku"}
                    rules={[{ required: true, message: "Please Enter SKU" }]}
                  >
                    <Input placeholder="sku" />
                  </Form.Item>
                </Col>
                <Col sm={6}>
                  <div className="input-form-label">Barcode</div>
                  <Form.Item name={"bar_code"}>
                    <Input placeholder="Barcode" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <div className="input-form-label">Purchase Price</div>
                  <Form.Item
                    name={"purchase_rate"}
                    rules={[
                      {
                        required: true,
                        message: "Enter Purchase rate of the Product",
                      },
                    ]}
                  >
                    <Input placeholder="0.00" type="number" max={1000000} />
                  </Form.Item>
                </Col>
                <Col sm={6}>
                  <div className="input-form-label">Retail Price</div>
                  <Form.Item
                    name={"retail_rate"}
                    rules={[
                      {
                        required: true,
                        message: "Enter Reail Rate of the product",
                      },
                    ]}
                  >
                    <Input placeholder="0.00" type="number" max={1000000} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col className="d-flex flex-column justify-content-end" sm={6}>
                  <div className="input-form-label">Stock</div>
                  <Form.Item
                    name={"unit"}
                    rules={[
                      {
                        required: true,
                        message: "Enter Available Units.",
                      },
                    ]}
                  >
                    <Input placeholder="Unit" type="number" max={10000} />
                  </Form.Item>
                </Col>
                <Col sm={6}>
                  <div className="input-form-label">
                    Available Stock (for bulk orders)
                  </div>
                  <Form.Item name={"units"}>
                    <Input
                      placeholder="Available Units"
                      max={10000}
                      type="number"
                      prefix={0}
                    />
                  </Form.Item>
                </Col>
                <Col sm={6}>
                  <div className="input-form-label">Accept bulk orders</div>
                  <Form.Item
                    name={"bulk_order"}
                    rules={[
                      {
                        required: true,
                        message:
                          "Please Select if you suppor Bulk order for this Product",
                      },
                    ]}
                  >
                    <Select
                      style={{ width: "100%" }}
                      className="border rounded"
                      allowClear
                      onChange={categoryChangeHandler}
                      placeholder="Select Bulk Order"
                    >
                      <Select.Option value={"Agree"}>Accept</Select.Option>
                      <Select.Option value={"notaccept"}>
                        Not Accept
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col sm={6}>
                  <div className="input-form-label">Product Status</div>
                  <Form.Item
                    name={"status"}
                    rules={[
                      {
                        required: true,
                        message: "Please Choose the Status of The product",
                      },
                    ]}
                  >
                    <Select
                      style={{ width: "100%" }}
                      className="border rounded"
                      allowClear
                      onChange={categoryChangeHandler}
                      placeholder="Select Status"
                    >
                      <Select.Option value={"available"}>
                        Available
                      </Select.Option>
                      <Select.Option value={"notavailable"}>
                        Not Available
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col sm={6}>
                  <div className="input-form-label">Pricing method*</div>
                  <Form.Item name={"price_method"}>
                    <Select
                      style={{ width: "100%" }}
                      allowClear
                      placeholder="pricing method"
                    >
                      <Select.Option value="Fixed">Fixed Rate</Select.Option>
                      <Select.Option value="Live">Live Rate</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col sm={6}>
                  <div className="input-form-label">Gender*</div>
                  <Form.Item
                    rules={[
                      { required: true, message: "Please select a gender" },
                    ]}
                    name={"gender"}
                  >
                    <Select
                      style={{ width: "100%" }}
                      allowClear
                      placeholder="Gender"
                    >
                      <Select.Option value="Male">Male</Select.Option>
                      <Select.Option value="Female">Female</Select.Option>
                      <Select.Option value="Child">Child</Select.Option>
                      <Select.Option value="Both">Both</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col sm={6}>
                  <div className="input-form-label">Vat</div>
                  <Form.Item
                    // rules={[
                    //   { required: true, message: "Please select a gender" },
                    // ]}
                    name={"vat"}
                  >
                    <Select
                      style={{ width: "100%" }}
                      allowClear
                      placeholder="Vat"
                    >
                      <Select.Option value="0">0%</Select.Option>
                      <Select.Option value="5">5%</Select.Option>
                      <Select.Option value="10">10%</Select.Option>
                      <Select.Option value="15">15%</Select.Option>
                      <Select.Option value="20">20%</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="input-form-label">
            Product Description and specifications
          </div>
          <div style={{ height: "270px" }}>
            <ReactQuill
              theme="snow"
              value={specifications}
              onChange={setSpecifications}
              style={{ height: "220px" }}
            />
          </div>

          <br />
          <Row>
            <Col sm={6}></Col>
            <Col sm={2}>
              {/* <Button size="large" block onClick={() => props?.onBack()}>
                Back
              </Button> */}
            </Col>
            <Col sm={4}>
              <Form.Item>
                <Button
                  size="large"
                  block
                  type="primary"
                  htmlType="submit"
                  loading={props?.isLoading}
                >
                  Update Details
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </Container>
  );
}
export default EditProductInformations;
